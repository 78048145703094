import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSText, EGDSHeading } from "@egds/react-core/text";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSCardContentSection, EGDSCardLink, EGDSCard } from "@egds/react-core/cards";
import { getAuthorFromCityStayedAtHotelText, getPostedAgoText } from "../../hotelRecentReviewsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { ThumbnailImageLeftCardProps } from "../../typings";

const ThumbnailImageLeftCard = (props: ThumbnailImageLeftCardProps) => {
  const { model, hotel } = props;
  const { subheadingForThumbnailImageLeftCardView, brandName } = model;
  const {
    hotelName,
    hotelImageUrl,
    hotelInfositeUrl,
    date: reviewPostedDate,
    rating,
    reviewTitle,
    reviewText,
    authorName,
    authorLocation,
  } = hotel;

  const { formatText } = useLocalization();

  const reviewTitleToDisplay = () => {
    const title =
      subheadingForThumbnailImageLeftCardView === "brandVerifiedReview"
        ? formatText("hotelRecentReviews.verifiedBrandReview", brandName)
        : reviewTitle;

    return (
      <EGDSSpacing margin={{ blockend: "two" }}>
        <EGDSText size={400}>{title}</EGDSText>
      </EGDSSpacing>
    );
  };

  return (
    <EGDSCard>
      <EGDSCardContentSection>
        <EGDSLayoutFlex>
          <EGDSLayoutFlexItem minWidth="third_width" shrink={0}>
            <div>
              <EGDSFigure className="uitk-card-roundcorner-all" imageFit="cover" ratio={EGDSFigure.AspectRatio.R4_3}>
                <EGDSImage src={hotelImageUrl} alt={hotelName} placeholderImage />
              </EGDSFigure>
            </div>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem grow={1}>
            <EGDSSpacing margin={{ inlinestart: "three" }}>
              <EGDSLayoutFlex direction="column" justifyContent="space-between">
                <EGDSHeading tag="h3" size={5} overflow="truncate-2-lines">
                  {hotelName}
                </EGDSHeading>
                <EGDSLayoutFlex direction="column">
                  <EGDSText size={300}>{formatText("hotelRecentReviews.ratingOutOf5", rating)}</EGDSText>
                  <EGDSText size={200}>{getPostedAgoText(reviewPostedDate)}</EGDSText>
                </EGDSLayoutFlex>
              </EGDSLayoutFlex>
            </EGDSSpacing>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCardContentSection>
      <EGDSCardContentSection border="top">
        {reviewTitleToDisplay()}
        <EGDSSpacing margin={{ blockend: "two" }}>
          <EGDSText size={200} overflow="truncate-2-lines">
            {`\u0022${reviewText}\u0022`}
          </EGDSText>
        </EGDSSpacing>
        <EGDSText size={200} overflow="truncate-2-lines">
          {getAuthorFromCityStayedAtHotelText(authorName, authorLocation, hotelName)}
        </EGDSText>
      </EGDSCardContentSection>
      <EGDSCardLink>
        <TrackedLink
          moduleName="hotelRecentReviews"
          rfrr="hotelInfositeLink"
          href={hotelInfositeUrl}
          data-testid={`hotel-recent-reviews-thumbnail-image-left-card-${hotelName}`}
        >
          {hotelName}
        </TrackedLink>
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default ThumbnailImageLeftCard;

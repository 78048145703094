import { useLocalization } from "@shared-ui/localization-context";

import ThumbnailImageLeftCardModuleTitle from "./views/components/ThumbnailImageLeftCardModuleTitle";
import HeaderImageCardModuleTitle from "./views/components/HeaderImageCardModuleTitle";
import ThumbnailImageLeftCard from "./views/components/ThumbnailImageLeftCard";
import HeaderImageCard from "./views/components/HeaderImageCard";
import { ViewTypes } from "./typings";

export const getAuthorFromCityStayedAtHotelText = (authorName: string, authorLocation: string, hotelName: string) => {
  const { formatText } = useLocalization();

  if (authorName) {
    return authorLocation
      ? formatText("hotelRecentReviews.authorText", authorName, authorLocation, hotelName)
      : formatText("hotelRecentReviews.authorTextWithoutCity", authorName, hotelName);
  }

  return authorLocation
    ? formatText("hotelRecentReviews.noNameAuthorText", authorLocation, hotelName)
    : formatText("hotelRecentReviews.noNameAuthorTextWithoutCity", hotelName);
};

export const getPostedAgoText = (datePosted: Date) => {
  const { formatText } = useLocalization();

  const hoursAgo = convertToHoursAgo(datePosted);
  const daysAgo = Math.round(hoursAgo / 24);
  const weeksAgo = Math.round(daysAgo / 7);
  const monthsAgo = Math.round(weeksAgo / 4);
  const yearsAgo = Math.round(monthsAgo / 12);

  switch (true) {
    case hoursAgo === 0:
      return formatText("hotelRecentReviews.postedLessThanAnHourAgo");
    case hoursAgo < 24:
      return formatText("hotelRecentReviews.postedHoursAgo", hoursAgo);
    case daysAgo < 14:
      return formatText("hotelRecentReviews.postedDaysAgo", daysAgo);
    case weeksAgo === 2 || weeksAgo === 3:
      return formatText("hotelRecentReviews.postedWeeksAgo", weeksAgo);
    case monthsAgo < 12:
      return formatText("hotelRecentReviews.postedMonthsAgo", monthsAgo);
    case yearsAgo === 1:
      return formatText("hotelRecentReviews.postedOneYearAgo");
    default:
      return formatText("hotelRecentReviews.postedOverYearAgo");
  }
};

export const convertToHoursAgo = (date: Date) => {
  const today = new Date();
  const datePosted = new Date(date);

  return Math.abs(Math.floor((today.getTime() - datePosted.getTime()) / 1000 / (60 * 60)));
};

export const ratingDescription = (rating: string) => {
  const { formatText } = useLocalization();

  switch (rating) {
    case "5":
      return formatText("hotelRecentReviews.excellent");
    case "4":
      return formatText("hotelRecentReviews.good");
    case "3":
      return formatText("hotelRecentReviews.okay");
    case "2":
      return formatText("hotelRecentReviews.poor");
    default:
      return formatText("hotelRecentReviews.terrible");
  }
};

export const viewTypes: ViewTypes = {
  moduleTitle: {
    "thumbnail-image-left-card": ThumbnailImageLeftCardModuleTitle,
    "header-image-card": HeaderImageCardModuleTitle,
  },
  topLevelComponentClassName: {
    "thumbnail-image-left-card": "HotelRecentReviewsThumbnailImageLeftCard",
    "header-image-card": "HotelRecentReviewsHeaderImageCard",
  },
  hotelCard: {
    "thumbnail-image-left-card": ThumbnailImageLeftCard,
    "header-image-card": HeaderImageCard,
  },
};

import * as React from "react";
import { EGDSHeading } from "@egds/react-core/text";
import { ThumbnailImageLeftCardModuleTitleProps } from "../../typings";

const ThumbnailImageLeftCardModuleTitle = (props: ThumbnailImageLeftCardModuleTitleProps) => {
  return (
    <EGDSHeading tag="h2" size={4}>
      {props.model.title}
    </EGDSHeading>
  );
};

export default ThumbnailImageLeftCardModuleTitle;

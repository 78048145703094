import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSScrim } from "@egds/react-core/scrim";
import { EGDSSubheading, EGDSText } from "@egds/react-core/text";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";

import { getAuthorFromCityStayedAtHotelText, getPostedAgoText, ratingDescription } from "../../hotelRecentReviewsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { HeaderImageCardProps } from "../../typings";

const HeaderImageCard = (props: HeaderImageCardProps) => {
  const { model, hotel } = props;
  const { showTitleOfReviewForHeaderImageView } = model;
  const {
    hotelName,
    hotelImageUrl,
    hotelInfositeUrl,
    date: reviewPostedDate,
    rating,
    reviewTitle,
    reviewText,
    authorName,
    authorLocation,
  } = hotel;

  const { formatText } = useLocalization();

  return (
    <EGDSCard border>
      <EGDSFigure ratio={EGDSFigure.AspectRatio.R21_9}>
        <EGDSImage src={hotelImageUrl} alt={hotelName} placeholderImage lazyLoading="lazy" />
        <EGDSScrim>
          <EGDSSubheading tag="h3">
            <EGDSText inline size={600} overflow="truncate-2-lines">
              {hotelName}
            </EGDSText>
          </EGDSSubheading>
        </EGDSScrim>
      </EGDSFigure>
      <EGDSCardContentSection>
        <EGDSLayoutFlex direction="column">
          <EGDSSpacing margin={{ blockend: "two" }}>
            <EGDSText size={400} weight="medium" theme="emphasis">
              {formatText("hotelRecentReviews.ratingSlashNoSpace", rating)} {ratingDescription(rating)}
            </EGDSText>
          </EGDSSpacing>
          {showTitleOfReviewForHeaderImageView && (
            <EGDSSpacing margin={{ blockend: "two" }}>
              <EGDSText size={300} weight="medium" theme="emphasis">
                {reviewTitle}
              </EGDSText>
            </EGDSSpacing>
          )}
          <EGDSSpacing margin={{ blockend: "two" }}>
            <EGDSText size={300} weight="medium" theme="emphasis" overflow="truncate-2-lines">
              {`\u0022${reviewText}\u0022`}
            </EGDSText>
          </EGDSSpacing>
          <EGDSSpacing margin={{ blockend: "two" }}>
            <EGDSText size={200} overflow="truncate-2-lines">
              {getAuthorFromCityStayedAtHotelText(authorName, authorLocation, hotelName)}
            </EGDSText>
          </EGDSSpacing>
          <EGDSText size={200}>{getPostedAgoText(reviewPostedDate)}</EGDSText>
        </EGDSLayoutFlex>
      </EGDSCardContentSection>
      <EGDSCardLink>
        <TrackedLink
          moduleName="hotelRecentReviews"
          rfrr="hotelInfositeLink"
          href={hotelInfositeUrl}
          data-testid={`hotel-recent-reviews-header-image-card-${hotelName}`}
        >
          {hotelName}
        </TrackedLink>
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default HeaderImageCard;
